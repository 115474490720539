@media screen and (min-width: 1920px) {

    #glasgow-school-of-art {
        width: 20%;
    }

    .second-intersection-figure-5 {
        padding-left: 7.5%;
        padding-right: 15%;
    }

    .sidebar-eye,
    .sidebar-eye-hover,
    .sidebar-eye-open,
    .sidebar-eye-hover-open {
        max-width: 80px;
        padding-right: 2em;
    }

    .row p,
    .container-text-col p {
        font-size: 1.5em;
    }

    .inter-container-text-col h2 {
        font-size: 2.6em;
    }

    h2,
    h3 {
        font-size: 2.3em;
    }

    /* iframe {
        height: 800px;
    } */

    .welcome-credits-container {
        font-size: 1.5em;
    }

    .making-waves-text-wrapper h2,
    .fifth-intertext-wrapper h2 {
        font-size: 2.8em;
    }

    .making-waves-text-wrapper h5 {
        font-size: 1.8em;
    }

    .making-waves-text-wrapper p,
    .container-text-col p,
    .fifth-intertext-wrapper p {
        font-size: 1.5em;
    }

    .making-waves-link {
        font-size: 1.3em;
    }

    .plus-icon {
        width: 1.8em;
    }

    .figcaption-text {
        font-size: 1.2em;
    }

    .main-menu-item-arrow .main-menu-item-text {
        font-size: 2em;
        line-height: 40px;
    }

    .sidebar-menu-item-text {
        font-size: 1.5em;
        line-height: 40px;
    }

    .sidebar-menu-item {
        margin: 20px 0;
    }

    .social-icons,
    .social-img {
        width: 1.5em;
        height: 1.5em;
    }

    .section-heading,
    .fourth-section-hero-heading {
        font-size: 4em;
    }

    .making-waves-heading {
        font-size: 8em;
    }

    .fourth-intersection-img-caption {
        font-size: 2.5em;
    }

    .fourth-section-hero-link {
        font-size: 1.3em;
    }

    .wider-context {
        font-size: 1.8em;
    }

    .more-info {
        font-size: 1.3em;
    }

    .data-heading {
        font-size: 3em;
    }

    .data-text {
        font-size: 1.2em;
    }
}